html {
  font-size: 62.5%;
}

body {
  font-size: $fontSizeMedium;
  letter-spacing: 0.02rem;
  color: $dark-gray;
  overscroll-behavior: none;

  .ant-tooltip-content {
    .ant-tooltip-inner {
      text-align: center !important;
    }
  }
}

html,
body {
  font-family: $fontFamily;
}

a,
a:hover {
  color: $main-blue;
}

svg,
g,
circle,
rect,
path {
  .lightblue--fill {
    fill: $light-blue;
  }

  .blue--fill {
    fill: $dark-blue;
  }

  .red--fill {
    fill: $red;
  }

  .green--fill {
    fill: $green;
  }

  .yellow--fill {
    fill: $yellow;
  }

  .blue--stroke {
    stroke: $dark-blue;
  }

  .lightblue--stroke {
    stroke: $light-blue;
  }

  .gray--fill {
    fill: $dark-gray;
  }
}

::-moz-selection {
  background: $light-blue !important;
  color: $white;
  border-radius: 8px;
}

::selection {
  background: $light-blue !important;
  color: $white;
  border-radius: 8px;
}

.Hidden {
  display: none;
}

.Hidden-Visibility {
  visibility: hidden;
}

.css-1go2l2o:hover {
  background: none !important;
  color: $white !important;
  cursor: pointer;
}

.ant-tooltip-disabled-compatible-wrapper,
.ant-tooltip-disabled-compatible-wrapper:hover,
.ant-tooltip-disabled-compatible-wrapper.ant-tooltip-open {
  border: none;
  background: transparent;
}

.slick-arrow {
  display: block;
  z-index: 1;

  &.slick-prev,
  &.slick-next {
    svg {
      width: 2rem;
      height: 2rem;
      margin-top: 1.65rem;
      filter: drop-shadow(1.75px 1.75px 3px rgba(0, 0, 0, 0.9));
    }

    path {
      fill: $white;
    }
  }

  &.slick-prev {
    left: 2.4rem !important;
  }

  &.slick-next {
    right: 2.4rem !important;
  }
}

#hubspot-messages-iframe-container {
  margin-bottom: 42px; // this height is chosen so it doesn't interfere with S3D's lower toolbar
}

.ant-menu::before {
  content: none !important;
}

.ant-menu::after {
  content: none !important;
}

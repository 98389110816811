@import 'styles/helpers';
@import 'styles/typography';

.Collapsible-Menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &-Main-Button {
    margin-right: 0.5rem;
    line-height: 2rem;
  }

  &--disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  &--icon-only {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    label .Collapsible-Menu-Label {
      display: none;
    }

    img.Collapsible-Menu-Label-Arrow {
      display: none;
    }

    .Collapsible-Menu-Label {
      padding: 0;
      padding-left: 0;
    }
  }

  input {
    display: none;
  }

  &-Label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem 0;
    padding-left: 0.25rem;
    cursor: pointer;
    transition: ease 0.3s;
    margin-bottom: 0;

    &-Arrow {
      align-self: center;
      transition: ease 0.4s;

      &--hidden {
        display: none;
      }
    }

    &:hover {
      color: $main-blue;
    }
  }

  .content {
    transition: ease 0.3s;
    background: inherit;
    border-left: 1px solid $light-gray;
    margin-left: 2.4rem;
    padding-left: 3.5rem;
    width: -webkit-fill-available;

    &--hidden {
      display: none;
    }

    &-child {
      transition: ease 0.3s;
      width: 100%;

      a {
        display: inline-block;
        color: $dark-gray;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.active {
          color: $main-blue;
          font-weight: $fontWeightBold;
        }
      }

      &--disabled {
        a {
          pointer-events: none;

          &:hover {
            cursor: not-allowed;
          }
        }
      }

      &--enabled {
        a {
          &:hover {
            color: $main-blue;
          }
        }
      }
    }
  }

  // The animation wasn't looking quite right so I just used display none
  input:checked + label + .content {
    display: block;
    opacity: 1;
    height: 100%;
    font-size: $fontSizeMediumSmall;

    .content-child {
      margin-bottom: 2rem;
      margin-top: 1rem;
      line-height: 2rem;
    }
  }

  input + label + .content--expanded {
    display: none;
    opacity: 0;
    height: 0;
    font-size: 0;

    .content-child {
      margin-bottom: 0;
      line-height: 0;
    }
  }

  input:checked + label .Collapsible-Menu-Label-Arrow {
    transform: rotate(0deg);
    transition: ease 0.4s;
  }

  input + label .Collapsible-Menu-Label-Arrow {
    transform: rotate(-180deg);
    transition: ease 0.4s;
  }
}

@import 'styles/helpers';
@import 'src/styles/typography';

.MapWrapper {
  position: relative;
  height: calc(100vh - 9.4rem);
  max-height: calc(100vh - 9.4rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .QRCode-Border {
    .ant-collapse-borderless,
    .ant-collapse {
      background-color: unset;

      .ant-collapse-item {
        border: none;
      }
    }
  }

  &-QRCodeContainer {
    margin: 0 2rem 1rem 2rem;
  }

  @media only screen and (max-width: $breakpoint-lg) {
    overflow-x: hidden;
  }

  @media only screen and (min-width: $breakpoint-lg) {
    flex-direction: row;
  }

  @media only screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xxl) {
    overflow: hidden;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    max-height: calc(100vh - 8rem);
  }

  &-Icon {
    &--Inverted {
      transform: scaleX(-1);
    }
  }

  &-Button:hover {
    & svg path {
      fill: $main-blue;
    }
  }

  &-Buttons {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;

    .Button--White {
      margin-bottom: 0.8rem;
      height: 3.4rem;
      display: flex;
      align-items: center;
      visibility: hidden;

      svg {
        margin-left: 1rem;
      }

      &:hover {
        opacity: 1;

        path {
          fill: $main-blue;
        }
      }

      &:disabled {
        path {
          fill: $mid-gray;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-lg) {
      visibility: hidden;
    }
  }

  &-Content {
    padding: 2.4rem 0;
    width: 100%;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    overflow-x: hidden;

    @media only screen and (min-width: $breakpoint-lg) {
      max-height: calc(100vh - 9.5rem);
      max-width: 59.2rem;
      overflow-y: auto;
    }

    &--ProjectStep {
      min-width: 59.2rem;
      overflow-y: auto;
      overflow-x: hidden;

      @media only screen and (max-width: $breakpoint-lg) {
        overflow: visible;
        min-width: 100%;
      }
    }

    &--Hidden {
      width: 0;
      padding: 0;
      visibility: hidden;
    }

    &--MapHidden {
      width: 100%;
      max-width: 100%;

      .Projects-Filter-Switch-Wrapper {
        display: none;
      }
    }
  }

  &-Mapbox {
    align-self: stretch;
    margin-top: 0.149rem;
    width: 100%;
    min-height: calc(100vh - 17.3rem);

    @media only screen and (min-width: $breakpoint-lg) {
      min-width: 45rem;
      height: calc(100vh - 9.55rem);
    }

    @media only screen and (min-width: $breakpoint-xl) {
      min-width: 55rem;
    }

    &--Hidden-Visibility {
      visibility: hidden;

      @media only screen and (max-width: $breakpoint-lg) {
        display: none;
      }
    }

    &--Hidden {
      display: none;
    }
  }
}
